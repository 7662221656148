
import { Component, Vue } from 'vue-property-decorator'
import { ProductLogDetail } from '@/types/product'

@Component({
  name: 'ProductLogDetail'
})
export default class extends Vue {
  private title = '产品类型详情'
  private info: ProductLogDetail = {
    id: '',
    userName: '',
    userLoginId: '',
    recordType: '',
    ctime: '',
    recordContent: ''
  }

  get productLogId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.productLogId) {
      this.getDetail()
    }
  }

  // 产品类型详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectProductRecordById, {
      id: this.productLogId
    }).then((res) => {
      this.info = res
    })
  }
}
